import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import {
  ArticleCacheList,
  useArticleCache,
} from "../../domains/article/hooks/useArticleCache";
import { SpecialtyModel } from "../../domains/app/app.types";
import { UNAVAILABLE_SPECIALTIES } from "../../tools/utils";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { SupportedLanguagesEnum } from "../../interfaces";
import { ProfessionModel } from "../../domains/profession/profession.types";
import { getProfessionsList } from "../../domains/profession/endpoints/getProfessionsList";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { patchUser } from "../../domains/user/endpoints/patchUser";
import { filterArrayDuplicates } from "../../domains/app/utils/filterArrayDuplicates";
import { setUser } from "../../redux/user/user.reducer";
import EditSpecialtyPanel from "./EditSpecialtyPanel";
import SaveHeader from "../../components/app/headers/SaveHeader";
import Loader from "../../components/Loader";
import CustomIcon from "../../components/CustomIcon";
import ProfilePicture from "../../components/ProfilePicture";
import disableScroll from "disable-scroll";
import { CustomButton } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import CustomSelect from "../../components/customSelect";
import CustomSheet from "../../components/CustomSheet";
import ConfirmModal from "../../components/app/ConfirmModal";
import { displayToast } from "../../components/app/AppToast";
import styled from "styled-components";
import { t } from "i18next";

export default function EditProfilePage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const articleCache = useArticleCache();
  const [isLoading, setLoading] = useState(true);
  const [professions, setProfessions] = useState<ProfessionModel[] | []>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [link, setLink] = useState("");
  const [bio, setBio] = useState("");
  const [selectedProfession, setSelectedProfession] =
    useState<ProfessionModel | null>(null);
  const [memSelectedProfession, setMemSelectedProfession] =
    useState<ProfessionModel | null>(null);
  const [selectedMainSpecialty, setSelectedMainSpecialty] =
    useState<SpecialtyModel | null>(null);
  const [selectedOtherSpecialties, setSelectedOtherSpecialties] = useState<
    SpecialtyModel[] | []
  >([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [awards, setAwards] = useState<any[]>([]);
  const [isAwardSheetVisible, setAwardSheetVisible] = useState(false);
  const [awardTitle, setAwardTitle] = useState("");
  const [awardYear, setAwardYear] = useState<number>(new Date().getFullYear());
  const [needSaving, setNeedSaving] = useState(false);
  const [isSpecialtyPanelVisible, setSpecialtyPanelVisible] = useState(false);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [editedSpecialty, setEditedSpecialty] = useState<SpecialtyModel | null>(
    null
  );
  const [
    lostSpecialtiesOnProfessionChange,
    setLostSpecialtiesOnProfessionChange,
  ] = useState<SpecialtyModel[]>([]);

  useEffect(() => {
    (async function () {
      try {
        window.scrollTo(0, 0);
        disableScroll.off();

        const { isRedirected } = await preflightUser({
          history,
          authOnly: true,
        });
        if (isRedirected) return;

        // NOTE: Load all professions to avoid further delays.
        setProfessions(await getProfessionsList());

        setLoading(false);
      } catch (error) {
        console.error("Couldn't mount edit profile page.", error);
        displayToast(t("error:default"));
        history.replace("/settings");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstname);
      setLastName(currentUser.lastname);
      setLink(currentUser.link);
      setBio(currentUser.bio);
      // setWorkplace(currentUser.location); // NOTE: all instances of "workplace/location" are commented until reconsideration
      setAwards(currentUser.awards);
      setSelectedProfession(currentUser.profession);
      setSelectedMainSpecialty(currentUser.main_specialty);
      setSelectedOtherSpecialties(currentUser.medical_specialties);
      setSelectedTags(currentUser.tags);
      // Used to cancel profession change
      setMemSelectedProfession(currentUser.profession);
    }
  }, [currentUser]);

  useEffect(() => {
    (async function () {
      try {
        if (!selectedProfession || !selectedMainSpecialty) return;

        const newProfession = selectedProfession;

        // If currently selected specialties don't exist in new profession
        const specialtiesNotIncluded = [
          selectedMainSpecialty,
          ...selectedOtherSpecialties,
        ].filter((spe: SpecialtyModel) => {
          return !newProfession.medical_specialties
            .map((proSpe: SpecialtyModel) => proSpe.uid)
            .includes(spe.uid);
        });
        setLostSpecialtiesOnProfessionChange(specialtiesNotIncluded);
      } catch (error) {
        console.error("Couldn't change main specialty.", error);
        displayToast(t("error:default"));
      }
    })();
  }, [selectedProfession, selectedMainSpecialty]);

  useEffect(() => {
    let changed = false;
    if (currentUser && !isLoading) {
      if (firstName !== currentUser.firstname) changed = true;
      if (lastName !== currentUser.lastname) changed = true;
      if (link !== currentUser.link) changed = true;
      if (bio !== currentUser.bio) changed = true;
      // if (workplace !== currentUser.location) changed = true;
      if (selectedProfession?.uid !== currentUser.profession.uid)
        changed = true;
      if (selectedMainSpecialty?.uid !== currentUser.main_specialty.uid)
        changed = true;
      if (
        selectedOtherSpecialties.map((s) => s.uid).join("") !==
        currentUser.medical_specialties
          .map((s: SpecialtyModel) => s.uid)
          .join("")
      )
        changed = true;
      if (
        selectedTags.map((s) => s.uid).join("") !==
        currentUser.tags.map((s: { uid: string }) => s.uid).join("")
      )
        changed = true;
      if (awards !== currentUser.awards) changed = true;
    }
    setNeedSaving(changed);
  }, [
    isLoading,
    firstName,
    lastName,
    link,
    bio,
    // workplace,
    selectedProfession?.uid,
    selectedMainSpecialty?.uid,
    selectedOtherSpecialties,
    selectedTags,
    awards,
  ]);

  function getAvailableSpecialties(
    profession: ProfessionModel = selectedProfession as ProfessionModel
  ) {
    const alreadyUsedSpecialties = [
      selectedMainSpecialty,
      ...selectedOtherSpecialties,
    ];
    return (profession as ProfessionModel).medical_specialties
      .filter((spe: SpecialtyModel) => {
        return !alreadyUsedSpecialties
          .map((usedSpe: any) => usedSpe.uid)
          .includes(spe.uid);
      })
      .filter((spe: SpecialtyModel) => {
        return !UNAVAILABLE_SPECIALTIES.includes(
          getItemTranslation(spe, SupportedLanguagesEnum.EN)
        );
      });
  }

  function isSpecialtyAlreadyUsed(specialtyUid: string) {
    return !getAvailableSpecialties()
      .map((s) => s.uid)
      .includes(specialtyUid);
  }

  async function handleChangeProfession(e: ChangeEvent<HTMLSelectElement>) {
    const newProfession: any = professions.find(
      (el) => el.uid === e.target.value
    );
    if (newProfession) setSelectedProfession(newProfession);
    else console.error("No such profession", e.target.value);
  }

  function handleAddAward() {
    const sortedAwards = [
      ...awards,
      {
        content: awardTitle,
        label: awardYear,
      },
    ];
    sortedAwards.sort((a, b) => parseInt(b.label) - parseInt(a.label));
    setAwards(sortedAwards);
    setAwardSheetVisible(false);
    setAwardTitle("");
    setAwardYear(new Date().getFullYear());
  }

  function handleDeleteAward(index: number) {
    const copyAwards = [...awards];
    copyAwards.splice(index, 1);
    setAwards(copyAwards);
  }

  function handleConfirmProfessionChange() {
    // Replace main specialty if incompatible
    if (
      lostSpecialtiesOnProfessionChange.find(
        (spe: SpecialtyModel) => spe.uid === selectedMainSpecialty?.uid
      )
    ) {
      const availableSpecialties = getAvailableSpecialties();
      setSelectedMainSpecialty(availableSpecialties[0]);
    }

    // Remove other specialties if incompatible
    setSelectedOtherSpecialties(
      selectedOtherSpecialties.filter((spe: SpecialtyModel) => {
        return !lostSpecialtiesOnProfessionChange.some(
          (lostSpe) => lostSpe.uid === spe.uid
        );
      })
    );

    // Remove tags from lost specialties
    setSelectedTags(
      selectedTags.filter(
        (tag: { uid: string; parent: { uid: string } | string }) => {
          // NOTE: Temp fix because of bad populate. Parent uid check should be enough.
          let isOfLostSpecialty = false;
          if (typeof tag.parent === "string") {
            const lostSpecialtiesIds = lostSpecialtiesOnProfessionChange.map(
              (spe: any) => spe._id
            );
            isOfLostSpecialty = lostSpecialtiesIds.includes(tag.parent);
          } else {
            const lostSpecialtiesUids = lostSpecialtiesOnProfessionChange.map(
              (spe: SpecialtyModel) => spe.uid
            );
            isOfLostSpecialty = lostSpecialtiesUids.includes(
              (tag.parent as { uid: string }).uid
            );
          }
          return !isOfLostSpecialty;
        }
      )
    );

    setMemSelectedProfession(selectedProfession);
    setLostSpecialtiesOnProfessionChange([]);
  }

  function handleCancelProfessionChange() {
    setSelectedProfession(memSelectedProfession);
    setLostSpecialtiesOnProfessionChange([]);
  }

  function handleUpdateSpecialtyAndTags(payload: {
    isMain: boolean;
    isNew: boolean;
    selectedSpecialty: SpecialtyModel;
    selectedTags: [];
  }) {
    if (payload.isMain) {
      setSelectedMainSpecialty(payload.selectedSpecialty);
    } else if (payload.isNew) {
      setSelectedOtherSpecialties(
        filterArrayDuplicates(
          [...selectedOtherSpecialties, payload.selectedSpecialty],
          (a: SpecialtyModel, b: SpecialtyModel) => a.uid === b.uid
        )
      );
    }

    let updatedTags = [...selectedTags, ...payload.selectedTags]
      // If tags have changed on the updated specialty, update that specialty only,
      // keeping the returned tags
      .filter((tag: { uid: string; parent: { uid: string } | string }) => {
        // NOTE: Temp fix because of bad populate. Parent uid check should be enough.
        let isOfUpdatedSpecialty = false;
        if (typeof tag.parent === "string") {
          isOfUpdatedSpecialty =
            tag.parent ===
            (payload.selectedSpecialty as unknown as { _id: string })._id;
        } else {
          isOfUpdatedSpecialty =
            (tag.parent as { uid: string }).uid ===
            payload.selectedSpecialty.uid;
        }

        if (isOfUpdatedSpecialty) {
          // Only keep the selected tags for this specialty
          return payload.selectedTags.some((selectedTag: { uid: string }) => {
            return selectedTag.uid === tag.uid;
          });
        } else {
          return true;
        }
      });

    // Clear previous main specialty tags when changed
    if (
      payload.isMain &&
      selectedMainSpecialty?.uid !== payload.selectedSpecialty.uid
    ) {
      updatedTags = updatedTags.filter(
        (tag: { uid: string; parent: { uid: string } | string }) => {
          // NOTE: Temp fix because of bad populate. Parent uid check should be enough.
          let isOfOldMainSpecialty = false;
          if (typeof tag.parent === "string") {
            isOfOldMainSpecialty =
              tag.parent ===
              (selectedMainSpecialty as unknown as { _id: string })._id;
          } else {
            isOfOldMainSpecialty =
              (tag.parent as { uid: string }).uid ===
              selectedMainSpecialty?.uid;
          }

          return !isOfOldMainSpecialty;
        }
      );
    }

    setSelectedTags(updatedTags);
  }

  async function handleDeleteSpecialty(deletedSpecialty: SpecialtyModel) {
    setSelectedOtherSpecialties(
      selectedOtherSpecialties.filter((s: SpecialtyModel) => {
        return s.uid !== deletedSpecialty.uid;
      })
    );

    setSelectedTags(
      selectedTags.filter((tag: { parent: { uid: string } | string }) => {
        // NOTE: Temp fix because of bad populate. Parent uid check should be enough.
        let isOfDeletedSpecialty = false;
        if (typeof tag.parent === "string") {
          isOfDeletedSpecialty =
            tag.parent === (deletedSpecialty as unknown as { _id: string })._id;
        } else {
          isOfDeletedSpecialty =
            (tag.parent as { uid: string }).uid === deletedSpecialty.uid;
        }
        return !isOfDeletedSpecialty;
      })
    );

    displayToast(t("toast.success.specialty_deleted"), "success");
  }

  function formatLink(inputLink: string): string | null {
    if (!inputLink) return null;
    if (inputLink.startsWith("http://") || inputLink.startsWith("https://")) {
      return inputLink;
    }
    return `https://${inputLink}`;
  }

  async function handleSubmit() {
    try {
      const formattedLink = formatLink(link);

      const urlRegex =
        "^(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";

      if (
        formattedLink !== null &&
        !new RegExp(urlRegex, "i").test(formattedLink)
      ) {
        displayToast(t("edit_profile.fail_link"));
        return;
      }

      const data: any = {
        firstname: firstName,
        lastname: lastName,
        bio,
        // location: workplace,
        link: formattedLink,
        awards,
        profession: selectedProfession?.uid,
        main_specialty: selectedMainSpecialty?.uid,
        medical_specialties: selectedOtherSpecialties?.map((tag) => tag.uid),
        tags: selectedTags?.map((tag) => tag.uid),
      };

      // Clear discovery carousel articles
      articleCache.saveList(ArticleCacheList.MAIN_SPECIALITY, []);
      articleCache.saveList(ArticleCacheList.SECONDARY_SPECIALITY, []);

      const updatedUser = await patchUser(data);
      dispatch(setUser(updatedUser));

      displayToast(t("profile.updated"), "success");
      history.goBack();
    } catch (error) {
      console.error("Couldn't edit profile.", error);
      displayToast(t("error:default"));
    }
  }

  if (isSpecialtyPanelVisible && selectedProfession && editedSpecialty) {
    return (
      <EditSpecialtyPanel
        editedSpecialty={editedSpecialty}
        availableSpecialties={
          editedSpecialty.uid === selectedMainSpecialty?.uid
            ? [selectedMainSpecialty, ...getAvailableSpecialties()]
            : getAvailableSpecialties()
        }
        preselectedTags={selectedTags}
        isMain={editedSpecialty?.uid === selectedMainSpecialty?.uid}
        isNew={!isSpecialtyAlreadyUsed(editedSpecialty?.uid)}
        onSubmit={(payload: any) => {
          handleUpdateSpecialtyAndTags(payload);
          setEditedSpecialty(null);
          setSpecialtyPanelVisible(false);
        }}
        onDelete={(specialty: SpecialtyModel) => {
          handleDeleteSpecialty(specialty);
          setEditedSpecialty(null);
          setSpecialtyPanelVisible(false);
        }}
        onClose={() => {
          setEditedSpecialty(null);
          setSpecialtyPanelVisible(false);
        }}
      />
    );
  }

  return (
    <>
      <SaveHeader
        title={t("edit_profile.title")}
        saveReady={needSaving}
        onSaveClick={handleSubmit}
        onGoBack={() => {
          if (needSaving) {
            setConfirmModalVisible(true);
          } else {
            history.goBack();
          }
        }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        // TODO: rework all the JSX
        <div style={{ minHeight: window.innerHeight * 0.8 }}>
          <EditAvatar
            onClick={() => history.push("/settings/edit/profile/avatar")}
          />

          <FormContainer>
            <h2>{t("Main information")}</h2>

            <CustomInput
              value={firstName}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value);
              }}
              placeholder={t("common:form.field.firstname")}
            />
            <div style={{ height: 15 }} />

            <CustomInput
              value={lastName}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value);
              }}
              placeholder={t("common:form.field.lastname")}
            />
            <div style={{ height: 15 }} />

            <CustomSelect
              label="Profession"
              value={selectedProfession?.uid}
              noEmptyOption
              renderOptions={professions.map(
                (el: ProfessionModel, index: number) => (
                  <option key={el.uid + index} value={el.uid}>
                    {getItemTranslation(el)}
                  </option>
                )
              )}
              onChange={handleChangeProfession}
            />

            <div style={{ height: 15 }} />

            <FakeSelect
              label={t("Main specialty")}
              content={
                selectedMainSpecialty
                  ? getItemTranslation(selectedMainSpecialty) ?? ""
                  : ""
              }
              onClick={async () => {
                setEditedSpecialty(selectedMainSpecialty);
                setSpecialtyPanelVisible(true);
              }}
            />

            <p className="disclaimer">
              {t("edit_profile.specialties_disclaimer")}
            </p>
            {selectedOtherSpecialties.map((otherSpecialty, index) => {
              return (
                <div
                  key={otherSpecialty.uid + "--edit-prof-sec-spe-key" + index}
                >
                  <FakeSelect
                    label={t("Secondary specialty")}
                    content={getItemTranslation(otherSpecialty)}
                    onClick={() => {
                      setEditedSpecialty(otherSpecialty);
                      setSpecialtyPanelVisible(true);
                    }}
                  />
                  <div style={{ height: 16 }} />
                </div>
              );
            })}

            <div
              className="flex-add-specialty"
              onClick={() => {
                const availableSpecialties = getAvailableSpecialties();
                if (availableSpecialties.length) {
                  setEditedSpecialty(availableSpecialties[0]);
                  setSpecialtyPanelVisible(true);
                }
              }}
            >
              <CustomIcon scale={1} iconName="add_circle" />
              <p>{t("edit_profile.add_specialty_btn")}</p>
            </div>

            <div style={{ height: 8 }} />

            <p className="disclaimer">{t("disclaimerEditLink")}</p>
            <CustomInput
              value={link}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setLink(e.target.value.toLowerCase())
              }
              placeholder="example.com"
            />
            <div style={{ height: 20 }} />

            <CustomInput
              value={bio}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setBio(e.target.value)
              }
              placeholder={t("Update your bio")}
              multiline
            />
            <div style={{ height: 20 }} />

            {/*
            <CustomInput
              value={workplace}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setWorkplace(e.target.value)
              }
              placeholder={t("profile.workplace")}
            />
            <div style={{ height: 20 }} />
            */}
          </FormContainer>

          <div style={{ height: 20, background: "white" }} />
          <FormContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setAwardYear(new Date().getFullYear());
                setAwardSheetVisible(true);
              }}
            >
              <h2>{t("profile.awards")}</h2>
              <CustomIcon scale={1.2} iconName="add_circle" />
            </div>

            {!awards?.length && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Inter",
                }}
              >
                {t("profile.add_award")} 🎖
              </p>
            )}
            {awards.map(
              (
                {
                  content,
                  label,
                }: {
                  content: string;
                  label: string;
                },
                index: number
              ) => (
                <AwardInput
                  key={index}
                  content={content}
                  label={label}
                  onDelete={() => handleDeleteAward(index)}
                />
              )
            )}
          </FormContainer>
          <div style={{ height: 100 }} />
        </div>
      )}
      <CustomSheet
        show={isAwardSheetVisible}
        title={t("profile.add_award")}
        onChange={() => setAwardSheetVisible(false)}
        bottomContent={
          <CustomButton
            disabled={!awardTitle?.length}
            type="button"
            style={{
              background: awardTitle?.length
                ? "linear-gradient(180deg, #FF699C 0%, #FE5763 100%)"
                : "#D2DCE2",
              color: awardTitle?.length ? "white" : "81939C",
            }}
            onClick={() => !!awardTitle.length && handleAddAward()}
          >
            {t("common:action.add")}
          </CustomButton>
        }
      >
        <h5>{t("Select the year and name of your award")}</h5>
        <CustomSelect
          background="#ECF0F5"
          label={t("Year")}
          options={(() => {
            const startingYear = 1950;
            const currentYear = new Date().getFullYear();
            const years = Array.from(
              new Array(currentYear - startingYear + 1),
              (_, n) => {
                return currentYear - n;
              }
            );
            return years;
          })()}
          noEmptyOption
          value={awardYear}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setAwardYear(() => parseInt(e.target.value));
          }}
        />
        <div style={{ height: 15 }} />

        <CustomInput
          background="#ECF0F5"
          placeholder={t("Award title")}
          value={awardTitle}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            setAwardTitle(e.target.value);
          }}
        />
        <div style={{ height: 100 }} />
      </CustomSheet>

      <ConfirmModal
        isVisible={isConfirmModalVisible}
        promptText={t("confirmExitWithoutSavingChanges")}
        onConfirm={() => history.replace("/settings")}
        onCancel={() => setConfirmModalVisible(false)}
      />
      <ConfirmModal
        isVisible={lostSpecialtiesOnProfessionChange.length > 0}
        promptText={t("confirmLostSpecialtiesOnProfessionChange")}
        onConfirm={handleConfirmProfessionChange}
        onCancel={handleCancelProfessionChange}
      />
    </>
  );
}

function EditAvatar({ onClick }: { onClick: Function }) {
  return (
    <div
      style={{
        position: "relative",
        height: "100px",
        width: "100px",
        margin: "20px auto 0",
      }}
      onClick={() => onClick()}
    >
      <ProfilePicture height={90} />
      <div style={{ position: "absolute", bottom: 0, right: 12 }}>
        <CustomIcon iconName="edit_avatar" />
      </div>
    </div>
  );
}

const FormContainer = styled.div`
  padding: 16px 40px;

  h2 {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #212121;
  }

  p {
    &.disclaimer {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      letter-spacing: 0.02em;
      color: #4c5861;
      margin: 24px 0 16px;
    }
  }

  .flex-add-specialty {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: start;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #ce0868;
      margin: 0;
    }
  }
`;

function FakeSelect({
  onClick,
  label,
  content,
}: {
  onClick: Function;
  label: string;
  content: string;
}) {
  return (
    <FakeSelectWrapper onClick={() => onClick()}>
      <div className="core">
        <div className="label">{label}</div>
        <div className="content">{content}</div>
      </div>
      <CustomIcon scale={0.8} iconName="select-arrow" />
    </FakeSelectWrapper>
  );
}

const FakeSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 8px 12px;

  .core {
    width: 100%;

    .label {
      font-family: "Inter";
      color: #81939c;
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 4px;
    }

    .content {
      font-family: "Inter";
      color: #212121;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

// TODO: Rework styles with proper classes/styled components.
function AwardInput({
  label,
  content,
  onDelete,
}: {
  label: string;
  content: string;
  onDelete: Function;
}) {
  return (
    <div
      style={{
        color: "#212121",
        fontFamily: "Inter",
        fontWeight: 400,
        background: "white",
        border: "none",
        borderRadius: "5px",
        padding: "12px 12px",
        margin: "15px 0",
        fontSize: "14px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p
                style={{
                  color: "#FF699C",
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
                {label}
              </p>
            </div>
            <div style={{ flex: 1, marginLeft: 6 }}>{content}</div>
          </div>
        </div>
        <div onClick={() => onDelete()}>
          <div style={{ marginLeft: -5 }}>
            <CustomIcon iconName="trash_circle" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { SupportedLanguagesEnum } from "../../interfaces";
import { OrganisationModel } from "../organisation/organisation.types";

// NOTE: Values are shared with the backend. Do not change.
export enum RoomType {
  DEFAULT = "Default",
  AUDIO = "Audio",
}

export interface RoomModel {
  _id: string;
  type: RoomType;
  isPublic: boolean;
  name: string;
  translations: { [key in SupportedLanguagesEnum]: any };
  organisation: OrganisationModel;
  primaryColor: string;
  articles: any[]; // TODO: Check type.
  contents: any[]; // TODO: Check type.
  playlists: string[];
  allowedLanguages: SupportedLanguagesEnum[];
  logo: {
    path: string;
    url: string;
  };
  ico: {
    path: string;
    url: string;
  };
}

export interface AudioRoomContext {
  topicName: string;
  sectionName: string;
}

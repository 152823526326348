import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { usePageScroll } from "../../../domains/app/hooks/usePageScroll";
import { ArticleCacheList, useArticleCache } from "../../../domains/article/hooks/useArticleCache";
import { useArticleSwipe } from "../../../domains/article/hooks/useArticleSwipe";
import { SpecialtyModel, TagModel } from "../../../domains/app/app.types";
import { useLazyGetArticlesListQuery } from "../../../domains/article/endpoints/getArticlesList";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import disableScroll from "disable-scroll";
import { gaEventViewItemListArticle } from "../../../tools/analytics/articleAnalytics";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { displayToast } from "../../../components/app/AppToast";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavLang } from "../../../components/app/headers/layout";
import BottomNavbar from "../../../components/app/BottomNavbar";
import FiltersDrawer from "../../../components/filters/FiltersDrawer";
import FadeIn from "react-fade-in/lib/FadeIn";
import SkeletonCard from "../../../components/skeleton/SkeletonCard";
import NoContent from "../../../components/NoContent";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemList from "../../../components/app/ItemList";
import HomeContentFilters from "../../../components/home/layout/HomeContentFilters";
import ArticleCard from "../../../components/cards/ArticleCard";
import styled from "styled-components";

export default function HomeContentPublicationsPage() {
  const location = useLocation<{ specialty?: SpecialtyModel }>();
  const params = useParams<{ slug: string }>();
  const { activeLang, t } = useLangContext();
  const articleCache = useArticleCache();
  const cachedArticles = articleCache.cacheLists[ArticleCacheList.CONTENT_LIST];
  const { savePageScrollY, restorePageScrollY } = usePageScroll();
  const { setSwipeCache } = useArticleSwipe();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const [isLoading, setLoading] = useState(true);
  const [articles, setArticles] = useState<any[]>([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [specialtyFilters, setSpecialtyFilters] = useState<any[] | null>(null);
  const [tagFilters, setTagFilters] = useState<any[] | null>(null);
  const [isFilterDrawerVisible, setFilterDrawerVisible] = useState(false);

  useEffect(() => {
    (async function () {
      disableScroll.off();
      const { speFilters, subSpeFilters } = await loadFilters();

      if (articles.length === 0 && cachedArticles.length >= 20) {
        setArticles(cachedArticles);
        setTotalArticles(1000); // NOTE: Force fetch when scrolling to the bottom of the list.

        setLoading(false);
        setTimeout(() => {
          restorePageScrollY();
        }, 100);
      } else {
        scrollTo(0, 0);
        fetchArticles(true, { speFilters, subSpeFilters });
      }
    })();
  }, []);

  useEffect(() => {
    // NOTE: Only occurs after first load
    if (!isLoading) {
      scrollTo(0, 0);
      fetchArticles(true);
    }
  }, [activeLang]);

  useEffect(() => {
    // NOTE: Only occurs after first load
    if (!isLoading) {
      console.log({ specialtyFilters });
      console.log({ tagFilters });

      if (specialtyFilters !== null && tagFilters !== null) {
        fetchArticles(true);
      }
    }
  }, [specialtyFilters, tagFilters]);

  useEffect(() => {
    if (articles.length > 0) {
      articleCache.saveList(ArticleCacheList.CONTENT_LIST, articles);
    }

    gaEventViewItemListArticle({
      articles,
      listName: AnalyticsItemList.HOME,
    });
  }, [articles]);

  async function loadFilters() {
    try {
      let speFilters = params.slug ? [{ uid: params.slug }] : [];
      let subSpeFilters: SpecialtyModel[] = [];

      // Load cached filters (no specialty)
      if (!params.slug && localStorage.getItem("homePublicationsFilters")) {
        const { medical_specialties, tags } = JSON.parse(
          localStorage.getItem("homePublicationsFilters") as string
        );
        if (medical_specialties.length > 0 || tags.length > 0) {
          speFilters = medical_specialties;
          subSpeFilters = tags;
        } else {
          localStorage.removeItem("homePublicationsFilters");
        }
      }

      // Load cached filters (specialty)
      if (params.slug && localStorage.getItem("homeSpecialtyFilters-" + params.slug)) {
        const { medical_specialties, tags } = JSON.parse(
          localStorage.getItem("homeSpecialtyFilters-" + params.slug) as string
        );
        if (medical_specialties.length > 0 || tags.length > 0) {
          speFilters = medical_specialties;
          subSpeFilters = tags;
        } else {
          localStorage.removeItem("homeSpecialtyFilters-" + params.slug);
        }
      }

      // Set default filters
      setSpecialtyFilters(speFilters);
      setTagFilters(tagFilters);

      console.log("C", { speFilters, subSpeFilters });
      return { speFilters, subSpeFilters };
    } catch (error) {
      console.error("Couldn't load filters.", error);
      return { speFilters: [], subSpeFilters: [] };
    }
  }

  async function fetchArticles(resetList = false, options: {
    speFilters?: { uid: string }[];
    subSpeFilters?: { uid: string }[];
  } = {}) {
    try {
      const fetchParams: any = {
        limit: 20,
        offset: resetList ? 0 : articles.length,
        language: activeLang,
      };

      const speFilters = specialtyFilters ?? options.speFilters ?? [];
      if (speFilters.length) {
        fetchParams.medicalSpecialties = speFilters.map((spe: SpecialtyModel) => spe.uid).join(",");
      }

      const subSpeFilters = tagFilters ?? options.subSpeFilters ?? [];
      if (tagFilters?.length) {
        fetchParams.tags = subSpeFilters.map((tag: TagModel) => tag.uid).join(",");
      }

      setLoading(true);
      await getArticlesList(fetchParams)
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...articles, ...docs];
          setArticles(newList);
          setTotalArticles(total);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't fetch articles.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters(filters: { medical_specialties: []; tags: [] }) {
    const { medical_specialties, tags } = filters;

    if (!medical_specialties.length && !tags.length) {
      localStorage.removeItem(!!params.slug
        ? "homeSpecialtyFilters-" + params.slug
        : "homePublicationsFilters"
      );
    } else {
      localStorage.setItem(!!params.slug
        ? "homeSpecialtyFilters-" + params.slug
        : "homePublicationsFilters",
        JSON.stringify({
          medical_specialties: Array.isArray(medical_specialties)
            ? medical_specialties
            : [{ uid: medical_specialties }],
          tags,
        })
      );
    }

    setSpecialtyFilters(Array.isArray(medical_specialties)
      ? medical_specialties
      : [{ uid: medical_specialties }]);
    setTagFilters(tags);
  }

  function handleExit() {
    articleCache.saveList(ArticleCacheList.CONTENT_LIST, []);
    history.back();
  }

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack onClick={handleExit} /></HeaderLinks>
          <HeaderTitle>
            {location.state?.specialty ? getItemTranslation(location.state?.specialty) : t("Publications")}
          </HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <HomeContentFilters
          active={params.slug
            ? tagFilters ? tagFilters.length > 0 : false
            : specialtyFilters ? specialtyFilters.length > 0 : false
          }
          onClick={() => setFilterDrawerVisible(true)}
        />

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <FadeIn key="loading">
              {[...Array(10)].map((_, index) => (
                <SkeletonCard key={`${index}--skeleton-card`} />
              ))}
            </FadeIn>
          ) : !articles.length ? (
            <NoContent
              specialty={params.slug
                ? getItemTranslation(location.state.specialty as SpecialtyModel)
                : t("your search")
              }
            />
          ) : (
            <FadeIn key="content">
              <InfiniteScroll
                dataLength={articles.length}
                next={fetchArticles}
                hasMore={articles.length < totalArticles}
                loader={null}
              >
                <ItemList>
                  {articles.map((article, index) => (
                    <ArticleCard
                      variant="white"
                      key={article.id}
                      article={article}
                      analyticsListName={AnalyticsItemList.HOME}
                      onClick={() => {
                        setSwipeCache(articles, index);
                        savePageScrollY();
                      }}
                    />
                  ))}
                </ItemList>
              </InfiniteScroll>
            </FadeIn>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      <FiltersDrawer
        showDrawer={isFilterDrawerVisible}
        onClose={() => setFilterDrawerVisible(false)}
        medical_specialties={specialtyFilters ?? []}
        tags={tagFilters}
        slug={params.slug}
        handleFilters={(filters: any) => {
          handleChangeFilters(filters);
          setFilterDrawerVisible(false);
        }}
        title={
          params.slug
            ? getItemTranslation(location.state.specialty as SpecialtyModel)
            : t("Publications")
        }
      />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: var(--BRAND-CORE-Juisci-Yellow, #FFC408);
`;

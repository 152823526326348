import React, { Component } from "react";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import styled from "styled-components";
import Loader from "../../components/Loader";
import CustomIcon from "../../components/CustomIcon";
import StoryVideo from "../../components/videos/StoryVideo";
import { Carousel } from "react-responsive-carousel";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import {
  maxVideoViewed,
  preventScroll,
} from "../../tools/utils";
import { isNativeIOS } from "../../tools/ios";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import { isNativeAndroid } from "../../tools/android";
import FadeIn from "react-fade-in/lib/FadeIn";
import ShareSheet from "../../components/ShareSheet";
import { getContentFromSlug } from "../../domains/content/endpoints/getContentFromSlug";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import { getVideoStoriesList } from "../../domains/video/endpoints/getVideoStoriesList";
import i18n from "../../config/i18n";

const CAROUSEL_PROPS = {
  axis: "vertical",
  showStatus: false,
  showThumbs: false,
  showArrows: false,
  centerMode: false,
  showIndicators: false,
  infiniteLoop: false,
  preventMovementUntilSwipeScrollTolerance: true,
};

const VIDEO_PER_PAGE = 7;

class VideoStoryFeed extends Component {
  playerRef = React.createRef();
  controlsRef = React.createRef();

  state = {
    isAuthentified: false,
    videoIndex: 0,
    isMuted: false,
    swipeScrollTolerance: 20,
    userSender: null,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    const isAuthentified = await isUserAuthentified();
    this.setState({ isAuthentified });

    if (!isAuthentified && maxVideoViewed("story")) {
      localStorage.setItem("authRedirectPath", this.props.location.pathname);
      displayToast(t("error:notLoggedIn"));
      this.props.history.replace("/login");
      return;
    }

    preventScroll(true);
    let story = this.props.location.state?.story;

    let stories;

    if (isAuthentified) this.fetchUser();

    if (!this.props.location.state?.stories) {
      // TODO: Use cache once we move to function component.
      const { docs } = await getVideoStoriesList({
        limit: 10,
        offset: 0,
        language: i18n.resolvedLanguage,
      });
      stories = docs;
    } else {
      stories = this.props.location.state.stories;
    }

    if (!story)
      story = stories.find((el) => el.slug === this.props.match.params.slug);

    if (!story) story = await getContentFromSlug(this.props.match.params.slug);

    // Avoir toute la liste et se mettre à la bonne position correspondant à la story en cours
    const storiesList = [
      ...stories.filter((el) => el.videoFormat === "story" && !!el.apiVideo),
    ];

    const videoIndex = storiesList.findIndex((el) => el._id === story._id);

    if (localStorage.getItem("storyFeedLastPosition")) {
      const lastPosition = parseInt(
        localStorage.getItem("storyFeedLastPosition")
      );
      this.setState({ videoIndex: lastPosition });
      localStorage.removeItem("storyFeedLastPosition");
    }

    if (videoIndex < 0) {
      this.setState({
        videoIndex: 0,
        storiesList: [story],
      });
    } else
      this.setState({
        videoIndex,
        storiesList,
      });
  }

  componentWillUnmount() {
    preventScroll(false);
  }

  fetchUser = async () => {
    if (this.props.user.isUninitialized) {
      await this.props.getUser();
      await this.props.getPlaylists();
    }
  };

  handleSwipeStart = (e) => {
    const fromBottom = e.targetTouches[0].clientY > window.innerHeight * 0.95;

    const carouselSlider =
      !!e.target?.className &&
      `${e.target?.className}`.includes("MuiSlider-thumb");

    // Si on essaye de scroll en étant dans la zone permettant de quitter l'app (home sur native)
    if (isNativeAndroid || isNativeIOS)
      this.setState({
        swipeScrollTolerance: fromBottom || carouselSlider ? 2000 : 0,
      });
    else {
      // Si on essaye de scroll en cliquant sur le slider
      this.setState({ swipeScrollTolerance: carouselSlider ? 2000 : 0 });
    }
  };

  handleCarouselChange = async (videoIndex) => {
    this.setState({ videoIndex });

    if (videoIndex === this.state.storiesList.length - 2) {
      const counter = videoIndex / (VIDEO_PER_PAGE - 2);
      const stories = this.props.videos.cache.videoStories.slice(
        counter * VIDEO_PER_PAGE,
        (counter + 1) * VIDEO_PER_PAGE
      );

      const storiesList = [...this.state.storiesList, ...stories];

      this.setState({
        storiesList,
      });
    }
  };

  render() {
    const { isAuthentified, storiesList, videoIndex } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const userSharing = query.get("shared");

    if (!storiesList || (isAuthentified && this.props.user.isUninitialized))
      return <Loader style={{ background: "#000" }} />;

    return (
      <>
        <StyledWrapper contentHeight={this.state.contentHeight}>
          <FadeIn visible={!!storiesList} delay={1500}>
            <div className="gradient">
              <SafeAreaTopWrapper float>
                <div
                  className='navbar'
                >
                  <CustomIcon
                    iconName='back-arrow'
                    color2='#CE0868'
                    onClick={() =>
                      !!this.props.location.key
                        ? this.props.history.goBack()
                        : this.props.history.push("/discovery/videos?wasShared=true")
                    }
                  />
                  <h5>{storiesList[this.state.videoIndex]?.title}</h5>
                  <div style={{ width: 32 }} />
                </div>
              </SafeAreaTopWrapper>
            </div>

            <Carousel
              {...CAROUSEL_PROPS}
              className='story-carousel'
              selectedItem={videoIndex}
              onChange={this.handleCarouselChange}
              swipeScrollTolerance={this.state.swipeScrollTolerance}
              onSwipeStart={this.handleSwipeStart.bind(this)}
              swipeable={!userSharing}
            >
              {storiesList?.map((item, index) =>
                index < this.state.videoIndex - Math.floor(VIDEO_PER_PAGE / 2) ||
                  index > this.state.videoIndex + Math.ceil(VIDEO_PER_PAGE / 2) ? (
                  <div
                    key={item._id + "--video-carousel"}
                    style={{
                      height: "100vh",
                      width: "100vw",
                      background: "black",
                    }}
                  />
                ) : (
                  <StoryVideo
                    key={item?._id + "--video-carousel"}
                    height='100vh'
                    video={item}
                    analyticsListName={AnalyticsItemList.STORY_LIST_PAGE}
                    isVisible={this.state.videoIndex === index}
                    onEnd={() =>
                      this.state.videoIndex < storiesList.length - 1 &&
                      this.setState({ videoIndex: this.state.videoIndex + 1 })
                    }
                    onClickLink={() => {
                      localStorage.setItem("storyFeedLastPosition", videoIndex.toString());
                    }}
                  />
                )
              )}
            </Carousel>
          </FadeIn>
        </StyledWrapper>
        <ShareSheet />
      </>
    );
  }
}

function mapState(state) {
  const user = state.user;
  const videos = state.videos;
  return {
    user,
    videos,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(VideoStoryFeed);

const StyledWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: black;
  position: relative;

  .story-carousel {
    height: 100vh;

    .carousel .slide iframe {
      width: 100% !important;
      margin: 0 !important;
    }

    .placeholder {
      position: relative;
      height: 100vh;
    }
  }

  .gradient {
    background: linear-gradient(
      180deg,
      rgba(49, 59, 66, 0.4) 0%,
      rgba(210, 220, 226, 0) 100%
    );
  }

  .navbar {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 23px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    h5 {
      font-family: "Inter";
      color: #fff;
      text-align: center;
      margin: 0;
      font-size: 14px;
      height: 33px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .--custom-icon {
      width: 32px;
      height: 32px;
    }
  }
`;

import { SupportedLanguagesEnum } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";
import { useVideoCache } from "../hooks/useVideoCache";
import { VideoFormat } from "../video.types";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  medicalSpecialties?: string;
  tags?: string;
}

export async function getVideoStoriesList(params: QueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/video`, {
      params: {
        limit: params.limit,
        offset: params.offset,
        language: params.language,
        medicalSpecialties: params.medicalSpecialties,
        tags: params.tags,
        videoFormat: VideoFormat.STORY,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export function useVideoStoriesListWithCache() {
  const { videoStories, setVideoStories, setTotalVideoStories } = useVideoCache();

  return async function (params: QueryParams) {
    const isAdding = params.offset === -1;
    if (isAdding) params.offset = videoStories.length;

    const { docs, meta } = await getVideoStoriesList(params);
    setVideoStories(isAdding ? [...videoStories, ...docs] : docs);
    setTotalVideoStories(meta.total);

    return { docs, meta };
  }
}

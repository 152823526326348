import { ReactChildren, ReactNode, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { NotificationModel } from "../../../domains/app/app.types";
import { UserModel } from "../../../domains/user/user.types";
import { isUserAuthentified } from "../../../domains/user/utils/isUserAuthentified";
import { useCurrentUser } from "../../../domains/user/hooks/useCurrentUser";
import { useUserConnection } from "../../../domains/user/hooks/useUserConnection";
import { getNotifications } from "../../../domains/notification/endpoints/getNotifications";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
  NavChat,
  NavNotifications,
  NavSettings,
  NavShare,
} from "./layout";
import { iosSetBackgroundColor, isNativeIOS } from "../../../tools/ios";
import { displayToast } from "../AppToast";
import { t } from "i18next";

export default function ProfileHeader({
  user,
  title = "",
}: {
  user: UserModel | null;
  title?: string;
}) {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { currentUser } = useCurrentUser();
  const [isAuthentified, setAuthentified] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [profileUser, setProfileUser] = useState<UserModel | null>(null);
  const isMe = profileUser?._id === currentUser._id;
  const { isConnected, isPendingSent, isPendingReceived } =
    useUserConnection(profileUser);
  // state = {
  //   followCount: 0,
  //   unreadMessagesCount: 0,
  // };

  useEffect(() => {
    (async function () {
      try {
        window.scrollTo(0, 0);
        if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

        const isAuthentified = await isUserAuthentified();
        setAuthentified(isAuthentified);

        // const { socket, unreadConversations, refreshConversations } =
        //   this.props.socketHook;
        // refreshConversations();

        // this.props.socketHook.refreshConversations();

        // Get all messages and requests if authentified
        if (isAuthentified) {
          //   i18n.changeLanguage(this.props?.user?.language);
          //   // Init page_view event...

          //   getListPendingFollowers().then((p_followers) => {
          //     this.setState({ followCount: p_followers?.length });
          //   });

          //   const currentUser = await getCurrentUser();
          //   this.setState({ currentUser });

          fetchNotifications();
        }

        // // Socket...
        // this.setState({ unreadMessagesCount: unreadConversations });

        // // Detecting if new message has come to refresh our newMsgCount...
        // socket?.on("newMessage", () => {
        //   refreshConversations();
        //   this.fetchNotifications();
        // });
      } catch (error) {
        console.error("Couldn't mount profile bar.", error);
        displayToast(t("error:default"));
      }
    })();
  }, []);

  useEffect(() => {
    setProfileUser(user ?? currentUser);
  }, [user]);

  async function fetchNotifications() {
    try {
      const { docs } = await getNotifications();
      const unreadNotifications = docs?.filter(
        (n: NotificationModel) => !n?.read && !n?.deleted
      );
      setUnreadNotificationsCount(unreadNotifications.length);
    } catch (error) {
      console.error("Couldn't fetch notifications.", error);
    }
  }

  function handleNavigateBack() {
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.get("shared")) {
      return history.replace("/discovery");
    }

    if (history.length <= 1) {
      return history.replace("/discovery");
    }

    // NOTE: Never used?
    // } else if (localStorage.getItem("wasShared")) {
    //   history.replace("/discovery");
    //   localStorage.removeItem("wasShared");

    history.goBack();
  }

  async function handleShareProfile() {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: t("shareUserProfile"),
          url: `/profile/user/${params.id}?shared=${currentUser.uid}`,
        });
        displayToast(t("toast.success.profile_shared"), "success");
      } else {
        throw new Error("Browser fail");
      }
    } catch (error) {
      console.error(error);
      displayToast(t("toast.fail.browser"));
    }
  }

  function handleCreateConversation() {
    if (isConnected) {
      history.push({
        pathname: "/profile/messages/conversation",
        state: { user: profileUser },
      });
      return;
    }

    // If not connected or pending, provide with feedback
    if (isPendingSent) {
      displayToast(
        t("Your request must be accepted before messaging with this user")
      );
    } else if (isPendingReceived) {
      displayToast(
        t("You must accept its request before messaging with this user")
      );
    } else {
      displayToast(t("toast.fail.need_follow"));
    }
  }

  return (
    <HeaderBoxAutoSafe>
      <HeaderLinks>
        <NavBack onClick={handleNavigateBack} />
      </HeaderLinks>
      {title && <HeaderTitle>{title}</HeaderTitle>}
      <HeaderLinks>
        {user && (
          <>
            {isMe ? (
              <>
                <NavSettings />

                <Link to="/profile/notifications">
                  {unreadNotificationsCount > 0 ? (
                    <NotifIconWrapper count={unreadNotificationsCount}>
                      <NavNotifications />
                    </NotifIconWrapper>
                  ) : (
                    <NavNotifications />
                  )}
                </Link>

                <Link to="/profile/messages">
                  <NavChat />
                  {/* {followCount > 0 || this.props.socketHook.unreadConversations > 0 ? (
                      <Badge
                        count={followCount + this.props.socketHook.unreadConversations}
                      >
                        <NavChat />
                      </Badge>
                    ) : (
                      <NavChat />
                    )} */}
                </Link>
              </>
            ) : (
              <>
                <NavShare onClick={handleShareProfile} />
                {isAuthentified && (
                  <NavChat onClick={handleCreateConversation} />
                )}
              </>
            )}
          </>
        )}
      </HeaderLinks>
    </HeaderBoxAutoSafe>
  );
}

function NotifIconWrapper({
  children,
  count,
}: {
  children: ReactNode | ReactChildren;
  count: number;
}) {
  return (
    <div style={{ position: "relative" }}>
      <span
        style={{
          position: "absolute",
          top: -5,
          right: -7,
          color: "#212121",
          background: "#FFC408",
          fontSize: "9px",
          fontWeight: 700,
          fontFamily: "Inter",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
        }}
      >
        {count > 99 ? 99 : count}
      </span>
      {children}
    </div>
  );
}

// export const Badge = (props) => {
//   return (
//     <div style={{ position: "relative" }}>
//       <span
//         style={{
//           position: "absolute",
//           top: -6,
//           right: -6,
//           color: "#212121",
//           background: "#FFC408",
//           fontSize: "9px",
//           fontWeight: 700,
//           fontFamily: "Inter",
//           borderRadius: "50%",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           width: "14px",
//           height: "14px",
//         }}
//       >
//         {props.count > 99 ? 99 : props.count}
//       </span>
//       {props.children}
//     </div>
//   );
// };

import { MouseEvent, useState } from "react";
import styled from "styled-components";
import { SpecialtyModel } from "../../domains/app/app.types";
import { VideoFormat, VideoModel } from "../../domains/video/video.types";
import {
  CardContainer,
  CardActions,
  CardDate,
  CardTags,
  CardType,
  CardTitle,
  CondensedCardFooter,
} from "./layout";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventSelectItemVideo } from "../../tools/analytics/videoAnalytics";
import { useHistory } from "react-router-dom";
import { useLikeVideo } from "../../domains/user/hooks/useLikeVideo";
import { useSaveVideo } from "../../domains/user/hooks/useSaveVideo";
import { useShareVideo } from "../../domains/user/hooks/useShareVideo";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { t } from "i18next";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";

export default function VideoCard({
  video,
  analyticsListName,
  className,
}: {
  video: VideoModel;
  analyticsListName: AnalyticsItemList;
  className?: string;
}) {
  const history = useHistory();
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const { isLiked, toggleLike } = useLikeVideo(video, analyticsListName);
  const { isSaved, toggleSaveToPlaylists } = useSaveVideo(video, analyticsListName);
  const shareVideo = useShareVideo(video, analyticsListName);
  const thumbnailUrl = video?.apiVideo?.thumbnail ?? `https://i3.ytimg.com/vi/${video?.sourceURL?.split("v=")[1]
    }/mqdefault.jpg` ?? "";

  function handleNavigate() {
    gaEventSelectItemVideo({
      video,
      listName: analyticsListName,
    });
    const path = video.videoFormat === VideoFormat.STORY
      ? `/video/story/${video.slug}`
      : `/video/${video.slug}`;
    history.push(path);
  }

  return (
    <>
      <CardContainer variant="dark" backgroundUrl={thumbnailUrl} blurThumbnail onClick={handleNavigate}>
        <CardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "8px",
            }}
          >
            <CardDate date={video.publication_date} />

            <CardTags contentLabel="video" tags={video.medical_specialties as SpecialtyModel[]} />
          </div>
          <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }}
          />
        </CardHeader>
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <CardType>{t("Video")}</CardType>
            <CardTitle lines={4}>{video.title}</CardTitle>
          </div>
          <div>
            <PlayIcon />
          </div>
        </CardBody>
        <CondensedCardFooter
          className="dark"
          companyName={video.company?.name ?? video.congress?.name ?? ""}
          companyLogo={video.company?.images ? video.company?.images[0]?.url : video.congress?.image?.url ?? ""}
        />
      </CardContainer>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onDismiss={() => setOptionsPanelVisible(false)}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareVideo}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={video}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({
          add,
          remove,
        }: {
          add: string[];
          remove: string[];
        }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

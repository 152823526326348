import i18n from "../../../config/i18n";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { RoomModel } from "../room.types";

export function getRoomTranslation(
  room: RoomModel,
  lang: SupportedLanguagesEnum | string = i18n.resolvedLanguage,
) {
  if (room.translations) {
    if (room.translations[lang as SupportedLanguagesEnum]) {
      return room.translations[lang as SupportedLanguagesEnum];
    }
    if (room.translations[SupportedLanguagesEnum.EN]) {
      return room.translations[SupportedLanguagesEnum.EN];
    }
  }
  return room.name;
}

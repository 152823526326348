import { useHistory } from "react-router-dom";
import { useCurrentUser } from "./useCurrentUser";
import { ArticleModel } from "../../article/article.types";
import { PlaylistModel } from "../../playlist/playlist.types";
import { useLazyGetPlaylistsQuery } from "../../../redux/user/user.service";
import { addArticleToPlaylist } from "../../playlist/endpoints/addArticleToPlaylist";
import { removeArticleFromPlaylist } from "../../playlist/endpoints/removeArticleFromPlaylist";
import { preflightUser } from "../utils/preflightUser";
import { useArticleCache } from "../../article/hooks/useArticleCache";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventArticleSave } from "../../../tools/analytics/articleAnalytics";
import { SoundEffect } from "../../../interfaces";
import { iosPlaySound } from "../../../tools/ios";

export function useSaveArticle(article: ArticleModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { updateArticleInAllCaches } = useArticleCache();
  const { playlists } = useCurrentUser();
  const isSaved = playlists && playlists?.some((playlist: PlaylistModel) => {
    return playlist.playlist.some((playlistArticle: ArticleModel) => {
      return playlistArticle._id === article?._id
    })
  });
  const [getPlaylists] = useLazyGetPlaylistsQuery();

  async function toggleSaveToPlaylists({ add, remove }: {
    add: string[];
    remove: string[];
  }) {
    try {
      if (!article) throw new Error("Invalid article.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.saveArticle"),
      });
      if (isRedirected) return;

      await Promise.all(add.map(playlistId => addArticleToPlaylist(playlistId, article.slug)));
      await Promise.all(remove.map(playlistId => removeArticleFromPlaylist(playlistId, article.slug)));
      // Refresh user playlists
      await getPlaylists({})
        .unwrap()
        .catch((error) => {
          console.error("Couldn't get user playlists.", error);
        });
      // Refresh metrics if article was cached
      updateArticleInAllCaches(article);

      if (add.length) {
        iosPlaySound(SoundEffect.SAVE);
        gaEventArticleSave({
          article,
          listName: analyticsListName,
        });
      }

      displayToast(t(add.length > 0
        ? "one more article was saved to your playlist"
        : "this article has been removed from your playlist"
      ));
    } catch (error) {
      console.error("Couldn't save/unsave article.", error);
      displayToast(t("error:default"));
    }
  }

  return {
    isSaved,
    toggleSaveToPlaylists,
  };
}

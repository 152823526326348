import React from "react";
import styled from "styled-components";
import { SupportedLanguagesEnum } from "../../interfaces";
import { useLangContext } from "../../domains/app/contexts/lang.context";

// Slide 1 - Rooms
import roomsImageEN from "../../assets/images/welcome-pics/4.0/rooms/rooms-en.png";
import roomsImageFR from "../../assets/images/welcome-pics/4.0/rooms/rooms-fr.png";

// Slide 2 - Slice of Science
import { ReactComponent as Fruit1 } from "../../assets/images/welcome-pics/4.0/slice-of-science/fruit-1.svg";
import { ReactComponent as Fruit2 } from "../../assets/images/welcome-pics/4.0/slice-of-science/fruit-2.svg";
import { ReactComponent as Fruit3 } from "../../assets/images/welcome-pics/4.0/slice-of-science/fruit-3.svg";
import { ReactComponent as Fruit4 } from "../../assets/images/welcome-pics/4.0/slice-of-science/fruit-4.svg";
// EN
import { ReactComponent as SliceOfScienceIconEN } from "../../assets/images/welcome-pics/4.0/slice-of-science/en/slice-of-science-icon-en.svg";
import videosPhone1EN from "../../assets/images/welcome-pics/4.0/slice-of-science/en/phone-1-en.png";
import videosPhone2EN from "../../assets/images/welcome-pics/4.0/slice-of-science/en/phone-2-en.png";
import videosPhone3EN from "../../assets/images/welcome-pics/4.0/slice-of-science/en/phone-3-en.png";
// FR
import { ReactComponent as SliceOfScienceIconFR } from "../../assets/images/welcome-pics/4.0/slice-of-science/fr/slice-of-science-icon-fr.svg";
import videosPhone1FR from "../../assets/images/welcome-pics/4.0/slice-of-science/fr/phone-1-fr.png";
import videosPhone2FR from "../../assets/images/welcome-pics/4.0/slice-of-science/fr/phone-2-fr.png";
import videosPhone3FR from "../../assets/images/welcome-pics/4.0/slice-of-science/fr/phone-3-fr.png";

// Slide 3 - JuisciGPT
// EN
import juisciGPTEN from "../../assets/images/welcome-pics/4.0/juisciGPT/juisciGPT-en.png";
// FR
import juisciGPTFR from "../../assets/images/welcome-pics/4.0/juisciGPT/juisciGPT-fr.png";

// Slide 4 - Lemon Certified
// EN
import playlist1EN from "../../assets/images/welcome-pics/4.0/lemon-certified/en/playlist-1-en.png";
import playlist2EN from "../../assets/images/welcome-pics/4.0/lemon-certified/en/playlist-2-en.png";
// FR
import playlist1FR from "../../assets/images/welcome-pics/4.0/lemon-certified/fr/playlist-1-fr.png";
import playlist2FR from "../../assets/images/welcome-pics/4.0/lemon-certified/fr/playlist-2-fr.png";

// Slide 5 - Connect with Peers
import JuisciVials from "../../assets/images/welcome-pics/4.0/connect-with-peers/juisci-vials.png";
// EN
import ConnectImageEN from "../../assets/images/welcome-pics/4.0/connect-with-peers/en/connect-en.png";
import ChatScreen1EN from "../../assets/images/welcome-pics/4.0/connect-with-peers/en/phone-1-en.png";
import ChatScreen2EN from "../../assets/images/welcome-pics/4.0/connect-with-peers/en/phone-2-en.png";
// FR
import ConnectImageFR from "../../assets/images/welcome-pics/4.0/connect-with-peers/fr/connect-fr.png";
import ChatScreen1FR from "../../assets/images/welcome-pics/4.0/connect-with-peers/fr/phone-1-fr.png";
import ChatScreen2FR from "../../assets/images/welcome-pics/4.0/connect-with-peers/fr/phone-2-fr.png";

import OrbitingProfilePictures from "./OrbitingProfilePictures";

interface SlideImage {
  src: string;
  className: string;
  style?: React.CSSProperties;
}

interface Slide {
  title: JSX.Element;
  text: JSX.Element;
  backColor: string;
  images: SlideImage[];
  backImage?: SlideImage;
  backImage2?: SlideImage;
  custom?: JSX.Element;
}

const CustomSliceOfScience: React.FC = () => {
  const { activeLang } = useLangContext();
  const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .slice-icon {
      height: 22dvh;
      opacity: 0;
      animation: fade-in 1s ease-in-out;
      animation-fill-mode: forwards;
      margin-bottom: -20px;
    }

    .fruit {
      position: absolute;
    }

    .blur {
      // SVG isn't blurred for some reason
      filter: blur(3px);
    }
  `;

  return (
    <StyledContainer>
      {activeLang === SupportedLanguagesEnum.EN ? (
        <SliceOfScienceIconEN className="slice-icon" />
      ) : (
        <SliceOfScienceIconFR className="slice-icon" />
      )}
      <Fruit1 className="fruit" style={{ top: 0, left: 0 }} />
      <Fruit2 className="fruit" style={{ top: 0, right: 0 }} />
      <Fruit3 className="fruit" style={{ top: "38%", left: 0 }} />
      <Fruit4 className="fruit blur" style={{ top: "45%", right: 0 }} />
    </StyledContainer>
  );
};

const CustomLemonCertified: React.FC = () => {
  const { activeLang } = useLangContext();
  const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    margin-top: 20px;

    img {
      width: auto;
      height: 210px;
      object-fit: cover;
      opacity: 0;
      animation: fade-in 1s ease-in-out;
      animation-fill-mode: forwards;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

  return (
    <StyledContainer>
      <img
        src={
          activeLang === SupportedLanguagesEnum.EN ? playlist1EN : playlist1FR
        }
        style={{ position: "absolute", top: 0, left: 30 }}
      />
      <img
        src={
          activeLang === SupportedLanguagesEnum.EN ? playlist2EN : playlist2FR
        }
        style={{
          position: "absolute",
          top: 60,
          right: 0,
          animationDelay: "0.3s",
        }}
      />
      <div
        style={{
          marginTop: "275px",
          marginBottom: "20px",
        }}
      >
        <OrbitingProfilePictures />
      </div>
    </StyledContainer>
  );
};

const CustomGlobalPeers: React.FC = () => {
  const { activeLang } = useLangContext();
  const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    .animation {
      opacity: 0;
      animation: fade-in 1s ease-in-out;
      animation-fill-mode: forwards;
    }

    .phone {
      width: 85vw;
      height: auto;
      opacity: 0;
      animation: fade-in 1s ease-in-out;
      animation-fill-mode: forwards;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

  return (
    <StyledContainer>
      <div
        style={{
          position: "relative",
          flex: 1,
          marginTop: "30px",
        }}
      >
        <img
          className="animation"
          src={JuisciVials}
          style={{
            width: "125px",
            height: "auto",
            marginLeft: "40px",
            animationDelay: "0.2s",
          }}
        />
        <img
          className="animation"
          src={
            activeLang === SupportedLanguagesEnum.EN
              ? ConnectImageEN
              : ConnectImageFR
          }
          style={{
            width: "195px",
            height: "auto",
            position: "absolute",
            top: 0,
            right: -20,
            animationDelay: "0.3s",
          }}
        />
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
        }}
      >
        <img
          src={
            activeLang === SupportedLanguagesEnum.EN
              ? ChatScreen1EN
              : ChatScreen1FR
          }
          className="phone"
          style={{
            marginLeft: "-22px",
            marginTop: "-20px",
            animationDelay: "0.5s",
          }}
        />
        <img
          src={
            activeLang === SupportedLanguagesEnum.EN
              ? ChatScreen2EN
              : ChatScreen2FR
          }
          className="phone"
          style={{
            position: "absolute",
            right: -68,
            bottom: -35,
            animationDelay: "0.6s",
          }}
        />
      </div>
    </StyledContainer>
  );
};

export const WELCOME_SLIDES: Record<SupportedLanguagesEnum, Slide[]> = {
  [SupportedLanguagesEnum.EN]: [
    // Slide 1
    {
      title: (
        <span className="c-black">
          {"🏠 Discover "}
          <div className="c-orange">{"In-App Rooms"}</div>
        </span>
      ),
      text: (
        <span className="c-black">
          <span className="c-grey">
            {"Access specialized, expert-curated content in "}
          </span>
          {"your exclusive rooms"}
          <span className="c-grey">
            {", brought to you by our trusted partners. Easily switch between "}
          </span>
          {"public and private spaces."}
        </span>
      ),
      backColor:
        "linear-gradient(352deg, rgba(210, 220, 226, 0.40) -4.64%, rgba(236, 240, 245, 0.40) 72.85%, rgba(252, 196, 8, 0.40) 149.37%), #FFF",
      images: [
        {
          src: roomsImageEN,
          className: "fade-slide-up",
          style: { width: "88%" },
        },
      ],
    },
    // Slide 2
    {
      title: (
        <span>
          <span className="c-black">{"🎬 Quick Insights with "}</span>
          <span className="c-orange">{"Slice of Science"}</span>
          <span className="c-black">{" Videos"}</span>
        </span>
      ),
      text: (
        <span className="c-black">
          {"Get the main research takeaways fast with videos that start with "}
          <span className="c-orange">{"conclusions "}</span>
          {"and "}
          <span className="c-orange">{"break down the essentials"}</span>
          {"."}
        </span>
      ),
      backColor: "#FFF9E6",
      images: [
        { src: videosPhone1EN, className: "fade-slide-up small-phone" },
        {
          src: videosPhone2EN,
          className: "rotate-left side-phone",
          style: {
            left: -25,
          },
        },
        {
          src: videosPhone3EN,
          className: "rotate-right side-phone",
          style: {
            right: -25,
          },
        },
      ],
      custom: <CustomSliceOfScience />,
    },
    // Slide 3
    {
      title: (
        <span className="c-white">
          {"✨ Your Smarter "}
          <div className="c-yellow">{"JuisciGPT"}</div>
        </span>
      ),
      text: (
        <span className="c-lightgrey">
          {"Interact with "}
          <span className="c-white">{"smarter content curation "}</span>
          {"and "}
          <span className="c-white">{"real-time updates "}</span>
          {"with our upgraded AI assistant"}
          <span className="c-white">{"."}</span>
        </span>
      ),
      backColor: "linear-gradient(225deg, #212121 0%, #4C5861 100%), #212121",
      images: [
        {
          src: juisciGPTEN,
          className: "fade-slide-up",
          style: { width: "88%" },
        },
      ],
    },
    // Slide 4
    {
      title: (
        <span className="c-black">
          {"🍋 Become "}
          <div className="c-lemon">{"Lemon Certified"}</div>
        </span>
      ),
      text: (
        <span className="c-black">
          {"Boost your visibility "}
          <span className="c-grey">{"by becoming lemon certified"}</span>
          {". Share your expertise "}
          <span className="c-grey">{"and "}</span>
          {"stand out "}
          <span className="c-grey">{"in the Juisci community"}</span>
          {"."}
        </span>
      ),
      backColor:
        "linear-gradient(180deg, rgba(249, 249, 249, 0.00) 33.85%, rgba(206, 8, 104, 0.20) 100%), #FFF",
      images: [],
      custom: <CustomLemonCertified />,
    },
    // Slide 5
    {
      title: <span className="c-black">{"🌍 Connect with Global Peers"}</span>,
      text: (
        <span className="c-black">
          {
            "Exchange content, share insights, and stay updated with global practices."
          }
        </span>
      ),
      backColor: "#FFF3CE",
      images: [],
      custom: <CustomGlobalPeers />,
    },
  ],
  [SupportedLanguagesEnum.FR]: [
    // Slide 1
    {
      title: (
        <span className="c-black">
          {"🏠 Découvrez "}
          <span className="c-orange">{"les espaces dédiés"}</span>
        </span>
      ),
      text: (
        <span className="c-black">
          <span className="c-grey">
            {
              "Accédez à des contenus spécialisés et sélectionnés par nos experts dans "
            }
          </span>
          {"vos espaces exclusifs"}
          <span className="c-grey">
            {
              ", proposés par nos partenaires de confiance. Naviguez facilement entre les espaces "
            }
          </span>
          {"publics et privés."}
        </span>
      ),
      backColor:
        "linear-gradient(352deg, rgba(210, 220, 226, 0.40) -4.64%, rgba(236, 240, 245, 0.40) 72.85%, rgba(252, 196, 8, 0.40) 149.37%), #FFF",
      images: [
        {
          src: roomsImageFR,
          className: "fade-slide-up",
          style: { width: "88%" },
        },
      ],
    },
    // Slide 2
    {
      title: (
        <span>
          <span className="c-black">
            {"🎬 Allez droit au but avec les vidéos "}
          </span>
          <span className="c-orange">{"‘Slice de Science’"}</span>
        </span>
      ),
      text: (
        <span className="c-black">
          {
            "Obtenez rapidement les points essentiels des publications avec des vidéos qui commencent par la "
          }
          <span className="c-orange">{"conclusion "}</span>
          {"puis "}
          <span className="c-orange">{"décomposent les éléments clés"}</span>
          {"."}
        </span>
      ),
      backColor: "#FFF9E6",
      images: [
        { src: videosPhone1FR, className: "fade-slide-up small-phone" },
        {
          src: videosPhone2FR,
          className: "rotate-left side-phone",
          style: {
            left: -25,
          },
        },
        {
          src: videosPhone3FR,
          className: "rotate-right side-phone",
          style: {
            right: -25,
          },
        },
      ],
      custom: <CustomSliceOfScience />,
    },
    // Slide 3
    {
      title: (
        <span className="c-white">
          {"✨ Votre "}
          <span className="c-yellow">{"JuisciGPT"}</span>
          {" augmenté"}
        </span>
      ),
      text: (
        <span className="c-lightgrey">
          {"Interagissez avec "}
          <span className="c-white">
            {"une sélection de contenus plus pertinents "}
          </span>
          {"et des "}
          <span className="c-white">{"mises à jour en temps réel "}</span>
          {"grâce à notre assistant d'IA augmenté"}
          <span className="c-white">{"."}</span>
        </span>
      ),
      backColor: "linear-gradient(225deg, #212121 0%, #4C5861 100%), #212121",
      images: [
        {
          src: juisciGPTFR,
          className: "fade-slide-up",
          style: { width: "88%" },
        },
      ],
    },
    // Slide 4
    {
      title: (
        <span className="c-black">
          {"🍋 Obtenez "}
          <span className="c-lemon">{"le label acidulé"}</span>
        </span>
      ),
      text: (
        <span className="c-black">
          {"Améliorez votre visibilité "}
          <span className="c-grey">{"en obtenant le label acidulé"}</span>
          {". Partagez votre expertise "}
          <span className="c-grey">{"et "}</span>
          {"démarquez-vous "}
          <span className="c-grey">{"au sein de la communauté Juisci"}</span>
          {"."}
        </span>
      ),
      backColor:
        "linear-gradient(180deg, rgba(249, 249, 249, 0.00) 33.85%, rgba(206, 8, 104, 0.20) 100%), #FFF",
      images: [],
      custom: <CustomLemonCertified />,
    },
    // Slide 5
    {
      title: (
        <span className="c-black">
          {"🌍 Connectez-vous avec des pairs à travers le monde"}
        </span>
      ),
      text: (
        <span className="c-black">
          {
            "Échangez du contenu, partagez des idées et restez informé des pratiques internationales."
          }
        </span>
      ),
      backColor: "#FFF3CE",
      images: [],
      custom: <CustomGlobalPeers />,
    },
  ],
  [SupportedLanguagesEnum.IT]: [], // NOTE: SupportedLanguagesEnum doesn't work without this
  [SupportedLanguagesEnum.ES]: [], // NOTE: SupportedLanguagesEnum doesn't work without this
};

import { useHistory } from "react-router-dom";
import { InfographicModel } from "../../interfaces/infographic.types";
import styled from "styled-components";
import {
  CardContainer,
  CardActions,
  CardDate,
  CardTags,
  CardType,
  CardTitle,
  CondensedCardFooter,
} from "./layout";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventSelectItemInfographic } from "../../tools/analytics/infographicAnalytics";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { t } from "i18next";
import { ReactComponent as InfographicIcon } from "../../assets/icons/image-icon.svg";

export default function InfographicCard({
  infographic,
  className,
  analyticsListName,
}: {
  infographic: any; // TODO: Fix with InfographicModel
  analyticsListName: AnalyticsItemList;
  className?: string;
}) {
  const history = useHistory();
  const thumbnailUrl = infographic.thumbnail?.url ?? "";

  function handleNavigate() {
    gaEventSelectItemInfographic({
      infographic,
      listName: analyticsListName,
    });
    history.push({
      pathname: `/infographic/${infographic.slug}`,
      state: { infographic },
    });
  }

  return (
    <CardContainer
      variant="dark"
      backgroundUrl={thumbnailUrl}
      onClick={handleNavigate}
    >
      <CardHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: "8px",
          }}
        >
          <CardDate date={infographic.publication_date} />
          <CardTags contentLabel="infographic" tags={infographic.medical_specialties} />
        </div>
        {/* <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }}
          /> */}
      </CardHeader>
      <CardBody>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <CardType>{t("contentType.infographic")}</CardType>
          <CardTitle lines={4}>{infographic.title}</CardTitle>
        </div>
        <div>
          <InfographicIcon />
        </div>
      </CardBody>
      <CondensedCardFooter
        className="dark"
        companyName={infographic.company?.name ?? infographic.congress?.name ?? ""}
        companyLogo={
          infographic.company?.images
            ? infographic.company?.images[0]?.url
            : infographic.congress?.image?.url
            ?? ""
        }
      />
    </CardContainer>
  );
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

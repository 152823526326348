import { ReactChildren, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { useRoomContext } from "./room.context";
import { DEFAULT_LANGUAGE, LOGIN_SIGNUP_LANGUAGES, PUBLIC_INAPP_LANGUAGES } from "../../../config/i18n";
import { useTranslation } from "react-i18next";
import { DebugLogger } from "../utils/debugLogger";
import {
  determineInitialRoomLang,
  determineInitialPublicAppLang,
  storeRoomLang,
  getStoredAppLang,
  getStoredRoomLang,
} from "../utils/langUtils";
import { RoomModel } from "../../room/room.types";

const debug = new DebugLogger("LangContext");
debug.mute();

export const LangContext = createContext<{
  publicAppLang: SupportedLanguagesEnum | null;
  setPublicAppLang: Function;
  currentRoomLang: SupportedLanguagesEnum | null;
  activeLang: SupportedLanguagesEnum;
  changeActiveLang: Function;
  t: any;
}>({
  publicAppLang: null,
  setPublicAppLang: () => null,
  currentRoomLang: null,
  activeLang: DEFAULT_LANGUAGE,
  changeActiveLang: () => null,
  t: () => null,
});

export function useLangContext() {
  const context = useContext(LangContext);
  if (context === undefined) {
    throw new Error("Missing provider for lang context");
  }
  return context;
}

export function LangProvider({ children }: {
  children: ReactNode | ReactChildren;
}) {
  const { i18n } = useTranslation();
  const {
    isInRoom,
    currentRoomId,
    allowedLanguages: roomAllowedLanguages,
    currentRoom,
  } = useRoomContext();
  const [publicAppLang, setPublicAppLang] = useState<SupportedLanguagesEnum | null>(null);
  const [currentRoomLang, setCurrentRoomLang] = useState<SupportedLanguagesEnum | null>(
    currentRoomId ? getStoredRoomLang(currentRoomId) : null
  );
  const isOnLoginSignup = ["/login", "/signup", "/terms", "/policy"].some((pathRoot: string) => location.pathname.startsWith(pathRoot));
  const activeLang = isInRoom
    ? currentRoomLang as SupportedLanguagesEnum
    : publicAppLang as SupportedLanguagesEnum ?? getStoredAppLang() ?? navigator.language.slice(0, 2) as SupportedLanguagesEnum;

  useEffect(() => {
    if (!isOnLoginSignup) {
      if (!isInRoom) {
        if (publicAppLang === null || !PUBLIC_INAPP_LANGUAGES.includes(publicAppLang)) {
          const lang = determineInitialPublicAppLang();
          debug.log("Set public app lang when null or not allowed", { currentRoomId, publicAppLang, lang });
          changeActiveLang(lang);
        }
      } else {
        const storedRoomLang = getStoredRoomLang(currentRoomId as string);

        if (storedRoomLang === null) {
          changeActiveLang(determineInitialRoomLang(currentRoom as RoomModel));
        }
        if (storedRoomLang && currentRoomLang !== storedRoomLang) {
          debug.log("Update room lang when different", { currentRoomId, currentRoomLang, storedRoomLang });
          changeActiveLang(storedRoomLang);
        }
      }
    }
    i18n.changeLanguage(activeLang);
    loadRoomLangFromStorage();
  }, [isInRoom]);

  // Change the currently used language (public or room)
  function changeActiveLang(lang: SupportedLanguagesEnum) {
    debug.log("Change active language", { lang, isInRoom });
    if (isInRoom) {
      if (!roomAllowedLanguages.includes(lang)) {
        debug.log("Lang not allowed in room", { lang, roomAllowedLanguages });
        lang = DEFAULT_LANGUAGE;
      }
      storeRoomLang(currentRoomId as string, lang);
    } else {
      const allowedLanguages = isOnLoginSignup
        ? LOGIN_SIGNUP_LANGUAGES
        : PUBLIC_INAPP_LANGUAGES;
      if (!allowedLanguages.includes(lang)) {
        lang = DEFAULT_LANGUAGE;
      }
      setPublicAppLang(lang);
      localStorage.setItem("appLang", lang);
    }
    i18n.changeLanguage(lang);
    loadRoomLangFromStorage();
  }

  function loadRoomLangFromStorage() {
    if (currentRoomId) {
      setCurrentRoomLang(getStoredRoomLang(currentRoomId));
    } else {
      setCurrentRoomLang(null);
    }
  }

  function t(key: string, options: any = {}) {
    // TODO: Move to utils.
    // NOTE: This hardcoded implementation is temporary.
    const roomOrganisationUid = currentRoom?.organisation?.uid ?? null;
    const isUsingFormalFrench = roomOrganisationUid?.toLowerCase().includes("takeda");
    if (isUsingFormalFrench && activeLang === SupportedLanguagesEnum.FR) {
      return i18n.t(key, { lng: "yo" }) as string;
    }

    return i18n.t(key, options) as string;
  }

  return (
    <LangContext.Provider value={{
      publicAppLang,
      setPublicAppLang,
      currentRoomLang,
      activeLang,
      changeActiveLang,
      t,
    }}>
      {children}
    </LangContext.Provider>
  );
}

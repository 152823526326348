import { SupportedLanguagesEnum } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";

interface QueryParams {
  language: SupportedLanguagesEnum;
}

export async function getLastContentInUserRooms(params: QueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/content/last-in-user-room`, {
      params
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

import styled from "styled-components";
import { MouseEvent, useState } from "react";
import { SpecialtyModel } from "../../domains/app/app.types";
import { NectarModel } from "../../domains/nectar/nectar.types";
import {
  CardContainer,
  CardActions,
  CardDate,
  CardTags,
  CardTitle,
  CondensedCardFooter,
  JuisciGPTLabel,
} from "./layout";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventSelectItemNectar } from "../../tools/analytics/nectarAnalytics";
import { useHistory } from "react-router-dom";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { useLikeNectar } from "../../domains/user/hooks/useLikeNectar";
import { useSaveNectar } from "../../domains/user/hooks/useSaveNectar";
import { useShareNectar } from "../../domains/user/hooks/useShareNectar";

export default function NectarCard({
  nectar,
  analyticsListName,
  onClick,
}: {
  nectar: NectarModel;
  analyticsListName: AnalyticsItemList;
  onClick?: Function;
}) {
  const history = useHistory();
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const { isLiked, toggleLike } = useLikeNectar(nectar, analyticsListName);
  const { isSaved, toggleSaveToPlaylists } = useSaveNectar(nectar, analyticsListName);
  const shareNectar = useShareNectar(nectar, analyticsListName);

  function handleNavigate() {
    gaEventSelectItemNectar({
      nectar,
      listName: analyticsListName,
    });
    history.push(`/nectar/${nectar.slug}`);
  }

  return (
    <>
      <CardContainer onClick={() => {
        onClick && onClick();
        handleNavigate();
      }}>
        <CardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "8px",
            }}
          >
            <CardDate date={nectar.publication_date} />
            <CardTags contentLabel="nectar" tags={nectar.medical_specialties as SpecialtyModel[]} />
          </div>
          <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }}
          />
        </CardHeader>
        <CardBody>
          <CardTitle lines={4}>{nectar.title}</CardTitle>
          <JuisciGPTLabel />
        </CardBody>
        {/* TODO: Nectar org/company info not found */}
        <CondensedCardFooter
          companyName={nectar.organisations?.[0]?.name}
          companyLogo={nectar.organisations?.[0]?.logo?.url}
        />
      </CardContainer>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onDismiss={() => setOptionsPanelVisible(false)}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareNectar}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={nectar}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({
          add,
          remove,
        }: {
          add: string[];
          remove: string[];
        }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

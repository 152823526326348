import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { RoomModel } from "../../domains/room/room.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useRoomPreflight } from "../../domains/room/hooks/useRoomPreflight";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import styled from "styled-components";
import { RoomHeader, RoomPageWrapper } from "../../components/room/layout";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  NavBack,
  NavLang,
} from "../../components/app/headers/layout";
import { displayToast } from "../../components/app/AppToast";
import { getRoomSharingQrCode } from "../../domains/room/endpoints/getRoomSharingQrCode";
import { AxiosError } from "axios";
import { ReactComponent as NoResultDrawing } from "../../assets/images/illustrations/search_no_results.svg";

export default function RoomShareQrCodePage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { currentRoomId, currentRoom } = useRoomContext();
  const { t } = useLangContext();
  const [isLoading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const { preflightRoom } = useRoomPreflight(roomId);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentRoomId) {
      scrollTo(0, 0);
      console.log({ currentRoomId });
      loadQrCode();
    }
  }, [currentRoomId]);

  async function loadQrCode() {
    try {
      const blob = await getRoomSharingQrCode(currentRoomId as string);
      const imgUrl = URL.createObjectURL(blob);
      setQrCode(imgUrl);

    } catch (error) {
      console.error("Couldn't load QR Code.", error);
      if ((error as AxiosError).response?.status !== 404) {
        displayToast(t("error:default"));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <RoomPageWrapper style={{ backgroundColor: "white" }}>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <RoomHeader room={currentRoom as RoomModel} />
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          {qrCode ? (
            <>
              <img className="qr-code" src={qrCode} alt="QR Code" />

              <span className="title">{t("room:shareQrCode.title")}</span>
              <p>{t("room:shareQrCode.description")}</p>
            </>
          ) : (
            <>
              <NoResultDrawing className="no-qrcode" />
              <p>{t("room:shareQrCode.notAvailable")}</p>
            </>
          )}
        </Container>
      )}
    </RoomPageWrapper>
  );
}

const Container = styled.div`
  padding: 96px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;

  .title {
    margin: 30px 40px 9px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: center;
    color: #212121;
  }

  p {
    margin: 0 22px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #212121;
  }

  .qr-code, .no-qrcode {
		display: block;
    width: 100%;
		max-width: 150px;
		height: 150px;
  }

  .no-qrcode {
		background: #eee;
		padding: 20px;
		border-radius: 50%;
  }
`;

import { getApiRequestService } from "../../../services/index.services";

export async function logoutUserBackend() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/auth/signout`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
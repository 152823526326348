import { IComment, SupportedLanguagesEnum } from ".";
import { ICompany } from "./company.interface";
import { JournalModel } from "../domains/journal/journal.types";
import { OrganisationModel } from "../domains/organisation/organisation.types";
import { SpecialtyModel, TagModel } from "../domains/app/app.types";

export type FetchContentParams = {
  limit: number;
  offset: number;
  language?: string;
  status?: string;
  medicalSpecialty?: string | undefined;
  tags?: string | undefined;
};

export enum ContentFormatsEnum {
  ARTICLE = "Article",
  VIDEO = "Video",
  PODCAST = "Podcast",
  GUIDELINE = "Guideline",
  NECTAR = "Nectar",
  INFOGRAPHIC = "Infographic",
}

export enum ContentStatusEnum {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum ContentVisibilityEnum {
  PUBLIC = "public",
  PRIVATE = "private",
}

export interface IContent {
  _id: string;
  content_format: ContentFormatsEnum;
  comments: IComment[];
  company: ICompany;
  journal: JournalModel;
  keywords: string[];
  language: SupportedLanguagesEnum;
  medical_specialties: SpecialtyModel[];
  meta?: {
    creationDate?: Date;
    lastModified?: Date;
    status?: ContentStatusEnum;
  };
  metrics?: {
    saveds: number;
    favorites: number;
    shares: number;
    views: number;
  };
  organisations: OrganisationModel[];
  publication_date: Date;
  slug: string;
  tags: TagModel[];
  title: string;
  versions: {
    [key: string]: any;
  };
  visibility: ContentVisibilityEnum;
}

import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import moment from "moment";
import {
  POPULAR_COUNTRY_CODES,
  getCountriesList,
  getCountriesListSortedByPopularity,
} from "../../domains/app/utils/countries";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderLogo,
  NavLang,
} from "../../components/app/headers/layout";
import { CustomButton } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import CustomDatePicker from "../../components/CustomDatePicker";
import ComboBox from "../../components/skeleton/ComboBox";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { useAppSelector } from "../../redux";
import { UserModel } from "../../domains/user/user.types";

export default function OnboardingProfile({
  userData,
  onSubmit,
}: {
  userData: {
    firstName: string;
    lastName: string;
  };
  onSubmit: Function;
}) {
  const { activeLang, t } = useLangContext();
  const [firstName, setFirstName] = useState(userData.firstName ?? "");
  const [lastName, setLastName] = useState(userData.lastName ?? "");
  const [birthDate, setBirthDate] = useState("");
  const [country, setCountry] = useState("");
  const countryOptions = getCountriesListSortedByPopularity(activeLang);
  const { user } = useAppSelector<{ user: UserModel }>((state) => state.user);

  console.log("onboarding[user]", user);

  useEffect(() => {
    if (user.firstname !== "" && firstName === "") setFirstName(user.firstname);
    if (user.lastname !== "" && lastName === "") setLastName(user.lastname);
  }, [user]);

  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();

    if ([firstName, lastName, country].some((field: string) => !field.length)) {
      displayToast(t("Please fill in all the fields"));
      return;
    }

    if (!getCountriesList()[country]) {
      displayToast(t("common:form.error.country"));
      return;
    }

    onSubmit({
      firstName,
      lastName,
      birthDate: birthDate ? new Date(birthDate) : null,
      country,
    });
  }

  return (
    <>
      <HeaderBoxAutoSafe>
        <HeaderLinks />
        <HeaderLogo />
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      <Wrapper>
        <Heading>
          <h1>{t("Fill in you info")}</h1>
          <span className='subtitle'>{t("common:fillInInformation")}</span>
        </Heading>

        <Form onSubmit={handleSubmit}>
          <CustomInput
            placeholder={t("common:form.field.firstname")}
            value={firstName}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setFirstName(e.target.value)
            }
            required
          />

          <CustomInput
            placeholder={t("common:form.field.lastname")}
            value={lastName}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setLastName(e.target.value)
            }
            required
          />

          <CustomDatePicker
            onChange={(date: Date) => {
              setBirthDate(moment(date).format("YYYY-MM-DD"));
            }}
          />

          <ComboBox
            placeholder={t("common:form.field.country")}
            options={countryOptions}
            value={country}
            groupBy={(option: { value: string }) =>
              POPULAR_COUNTRY_CODES.includes(option.value)
                ? t("Most selected")
                : t("Other countries")
            }
            onChange={(country: { value: string }) =>
              !!country && setCountry(country.value)
            }
            required
          />

          <CustomButton type='submit' className='primary'>
            {t("common:action.next")}
          </CustomButton>
          {/* 
          
           */}
        </Form>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ecf0f5;
`;

const Heading = styled.div`
  margin: 24px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .organisation-logo {
    object-fit: contain;
    width: 150px;
    max-height: 48px;
    margin: 21px 0;
    padding: 16px;
    border-radius: 15px;
    background: white;
  }

  h1 {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #212121;
  }

  .subtitle {
    display: block;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #212121;
  }
`;

const Form = styled.form`
  box-sizing: border-box;
  width: 100%;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

import { ContentFormatsEnum } from "../../interfaces/content.interface";
import { SpecialtyModel } from "../app/app.types";
import { ArticleModel } from "../article/article.types";
import { JournalModel } from "../journal/journal.types";
import { OrganisationModel } from "../organisation/organisation.types";
import { UserModel } from "../user/user.types";

export enum VideoFormat {
  ARTICLE = "article",
  STORY = "story",
}

export interface VideoModel {
  _id: string;
  slug: string;
  title: string;
  type?: ContentFormatsEnum;
  content_format?: string;
  publication_date?: Date;
  medical_specialties?: SpecialtyModel[];
  organisations?: OrganisationModel[];
  associatedArticles?: ArticleModel[];
  videoFormat: VideoFormat;
  sourceURL: string;
  apiVideo?: {
    videoId: string;
    thumbnail: string;
    player: string;
  };
  publisher?: "congress" | "journal" | "user";
  journal?: JournalModel;
  congress?: {
    image: { url: string; };
    name: string;
  };
  user?: UserModel;
  // TODO: temporary
  company?: {
    _id: string;
    id: string;
    images: {
      url: string;
    }[];
    name: string;
  };
}

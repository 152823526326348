import { Component } from "react";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { StyledOnboarding } from "../../components/onboarding/StyledOnboarding";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack } from "../../components/app/headers/layout";
import CustomSearchInput from "../../components/CustomSearchInput";
import { t } from "i18next";
import styled from "styled-components";
import { CustomButton, Spacer } from "../../components/global";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import Fuse from "fuse.js";
import { getTagsList } from "../../domains/app/endpoints/getTagsList";
import { patchUser } from "../../domains/user/endpoints/patchUser";
import { displayToast } from "../../components/app/AppToast";
import { store } from "../../redux";
import { ArticleCacheList, articleCacheStore } from "../../domains/article/hooks/useArticleCache";
import { setUser } from "../../redux/user/user.reducer";
import { gaEventUserUpdateInterests } from "../../tools/analytics/userAnalytics";
import { iosGenerateHaptic } from "../../tools/ios";
import { HapticEffect } from "../../interfaces";
import { UNAVAILABLE_SPECIALTIES } from "../../tools/utils";
import { withRouter } from "react-router-dom";
import i18n from "../../config/i18n";

class HomeSpecialties extends Component {
  state = {
    isLoading: true,
    searchValue: "",
    medical_specialties: undefined,
    searchResults: undefined,
    selections: [],
    submitted: false,
  };

  async componentDidMount() {
    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.default"),
    });
    if (isRedirected) return;

    await this.handleFetchSpecialties();
    this.setState({ isLoading: false });
  }

  handleSearch(searchValue) {
    const { medical_specialties } = this.state;
    this.setState({ searchValue });

    if (searchValue.length) {
      const options = {
        includeScore: true,
        threshold: 0.25,
        keys: [`translations.${i18n.resolvedLanguage}`],
      };

      const fuse = new Fuse(medical_specialties, options);

      let searchResults = fuse.search(searchValue).map((res) => res.item);

      this.setState({ searchResults });
    } else {
      this.setState({ searchResults: undefined });
    }
  }

  handleCancelSearch() {
    this.setState({ searchValue: "" });
  }

  handleFetchSpecialties = async () => {
    if (!this.props.user.user) await this.props.getUser();

    const { profession, main_specialty, medical_specialties } =
      this.props.user.user;

    this.setState({
      medical_specialties: profession.medical_specialties.filter(
        (el) => el.uid !== main_specialty.uid
      ),
      selections: !!medical_specialties ? [...medical_specialties] : [],
    });
  };

  handleSelect = (specialty) => {
    iosGenerateHaptic(HapticEffect.SELECTION);
    if (specialty === "all") {
      if (
        this.state.selections.length ===
        this.props.user.user.profession.medical_specialties.length
      )
        this.setState({
          selections: [],
        });
      else
        this.setState({
          selections: [...this.props.user.user.profession.medical_specialties],
        });
    } else {
      if (this.state.selections.map((el) => el.uid).includes(specialty.uid))
        this.setState({
          selections: [
            ...this.state.selections.filter((el) => el.uid !== specialty.uid),
          ],
        });
      else
        this.setState({
          selections: [...this.state.selections, specialty],
        });
    }
  };

  // TODO: Missing catch.
  handleSubmit = async () => {
    this.setState({ submitted: true });
    const medical_specialties = [...this.state.selections.map((el) => el.uid)];
    const tags = [...(await getTagsList())]
      .filter(
        (el) => !!el.parent && medical_specialties.includes(el.parent.uid)
      )
      .map((el) => el.uid);

    const updatedUser = await patchUser({ medical_specialties, tags });
    store.dispatch(setUser(updatedUser));
    articleCacheStore.saveList(ArticleCacheList.SECONDARY_SPECIALITY, []);

    gaEventUserUpdateInterests(this.state.selections);

    displayToast(
      this.state.selections.length > 1
        ? t("toast.success.specialties_edit")
        : t("toast.success.specialty_edit")
    );
    setTimeout(() => this.props.history.goBack(), 1500);
  };

  render() {
    if (!this.props.user.user || this.state.isLoading) return <Loader />;

    const hideFooter =
      this.state.selections?.map((el) => el.uid).toString() ===
      this.props.user.user.medical_specialties?.map((el) => el.uid).toString();

    const allSelected =
      this.props.user.user &&
      this.state.selections.length ===
      this.props.user.user.profession.medical_specialties.length;

    return (
      <PageContainer>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderLogo />
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        {this.state.medical_specialties ? (
          <div>
            <h5>{t("home:specialties.addYourFavorite")}</h5>
            <CustomSearchInput
              placeholder={t("common:action.search")}
              onSearch={this.handleSearch.bind(this)}
              onClear={() =>
                this.setState({
                  searchResults: undefined,
                  searchValue: "",
                })
              }
            />
            <Spacer height="8px" />
            <h3>{t("common:default")}</h3>
            <div className="selection-rows" style={{ padding: "0px 22px" }}>
              <div className="row no-border">
                <p>{t("home:specialties.allSpecialties")}</p>
                <button
                  className={allSelected ? "selected" : ""}
                  onClick={() => this.handleSelect("all")}
                >
                  {t(allSelected ? "common:action.unselect" : "common:action.select")}
                </button>
              </div>
            </div>
            <hr style={{ margin: "16px 0" }} />
            <h3>{t("common:specialties")}</h3>
            <div className="selection-rows">
              {(!!this.state.searchResults
                ? this.state.searchResults
                : this.state.medical_specialties
              )
                ?.reverse()
                .sort((a, b) => getItemTranslation(a).localeCompare(getItemTranslation(b)))
                .filter(
                  (el) => !UNAVAILABLE_SPECIALTIES.includes(getItemTranslation(el, "en"))
                )
                .map((specialty) => (
                  <div
                    className="row"
                    key={specialty?._id + "--home-specialties-row"}
                  >
                    <p>{getItemTranslation(specialty)}</p>
                    <button
                      onClick={() => this.handleSelect(specialty)}
                      className={
                        this.state.selections.find(
                          (el) => el.uid === specialty.uid
                        )
                          ? "selected"
                          : ""
                      }
                    >
                      {t(
                        this.state.selections.find(
                          (el) => el.uid === specialty.uid
                        )
                          ? "common:action.unselect"
                          : "common:action.select"
                      )}
                    </button>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <div className={`footer ${hideFooter ? "hide" : ""}`}>
          <CustomButton
            className={this.state.submitted ? "loading disabled" : ""}
            onClick={this.handleSubmit.bind(this)}
          >
            {t("home:specialties.action.saveFavorite")}
          </CustomButton>
        </div>
      </PageContainer>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(withRouter(HomeSpecialties));

const PageContainer = styled(StyledOnboarding)`
  padding: 0; // Overwrite
  background: #ffffff;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 100px;

  .--custom-search {
    margin: auto 21px;
  }

  h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: left;
    margin: 32px 21px;
    padding: 0;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 100%;
    color: #ce0868;
    margin: 32px 21px 0px;
  }

  .row.no-border {
    border: none;
  }
`;

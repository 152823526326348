import { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { Widget } from "@typeform/embed-react";
import styled from "styled-components";
import AppButton from "../../components/app/AppButton";

const FORM_ID = "DTKOlpZP";

export default function RoomQuizPage() {
  const params = useParams<{ roomId: string }>();
  const { t } = useLangContext();
  const [isFinished, setFinished] = useState(false);
  const footerCacheRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <div className="header-cache"></div>
      {!isFinished ? (
        <Widget
          id={FORM_ID}
          className="form"
          onReady={() => {
            const footerCache = footerCacheRef.current;
            if (footerCache) footerCache.style.display = "none";
            setFinished(false);
          }}
          onStarted={() => {
            const footerCache = footerCacheRef.current;
            if (footerCache) footerCache.style.display = "block";
          }}
          onSubmit={() => {
            setFinished(true);
          }}
          opacity={0}
        />
      ) : (
        <Link to={`/room/discovery/${params.roomId}`}>
          <AppButton>{t("common:action.back")}</AppButton>
        </Link>
      )}
      <div ref={footerCacheRef} className="footer-cache" style={{ display: "none" }}></div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header-cache {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
    height: 64px;
  }

  .footer-cache {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
    height: 40px;
  }

  .form {
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100dvh;
  }
`;

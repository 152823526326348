import { atom, useAtom } from "jotai";
import { PlaylistModel } from "../playlist.types";
import { DebugLogger } from "../../app/utils/debugLogger";

const debug = new DebugLogger("Playlist Cache");
// debug.mute();

export enum Cache {
  RECOMMENDED = "recommended",
  CERTIFIED = "certified",
  ROOM = "room",
  LIST_PAGE = "list_page",
}

interface PlaylistCacheData {
  list: PlaylistModel[];
  total: number;
}

interface CacheLists {
  [Cache.RECOMMENDED]: PlaylistCacheData;
  [Cache.CERTIFIED]: PlaylistCacheData;
  [Cache.ROOM]: PlaylistCacheData;
  [Cache.LIST_PAGE]: PlaylistCacheData;
}

const defaultCacheLists: CacheLists = {
  [Cache.RECOMMENDED]: { list: [], total: 0 },
  [Cache.CERTIFIED]: { list: [], total: 0 },
  [Cache.ROOM]: { list: [], total: 0 },
  [Cache.LIST_PAGE]: { list: [], total: 0 },
};

const cacheListsAtom = atom<CacheLists>(defaultCacheLists);

export function usePlaylistCache() {
  const [cacheLists, setCacheLists] = useAtom(cacheListsAtom);

  function saveCache(listType: Cache, docs: PlaylistModel[], total: number) {
    debug.log("Save cache", { listType, docs, total });
    setCacheLists((lists) => ({ ...lists, [listType]: { list: docs, total } }));
  }

  return {
    clearAllCaches: () => setCacheLists(defaultCacheLists),
    // Public playlists
    publicPlaylists: cacheLists[Cache.RECOMMENDED].list,
    totalPublicPlaylists: cacheLists[Cache.RECOMMENDED].total,
    setPublicPlaylists: (playlists: PlaylistModel[], total: number) => {
      saveCache(Cache.RECOMMENDED, playlists, total);
    },
    // Certified/KOL playlists
    certifiedPlaylists: cacheLists[Cache.CERTIFIED].list,
    totalCertifiedPlaylists: cacheLists[Cache.CERTIFIED].total,
    setCertifiedPlaylists: (playlists: PlaylistModel[], total: number) => {
      saveCache(Cache.CERTIFIED, playlists, total);
    },
    // Playlists displayed in rooms
    roomPlaylists: cacheLists[Cache.ROOM].list,
    totalRoomPlaylists: cacheLists[Cache.ROOM].total,
    setRoomPlaylists: (playlists: PlaylistModel[], total: number) => {
      saveCache(Cache.ROOM, playlists, total);
    },
    // Playlists displayed in the content list page
    listPagePlaylists: cacheLists[Cache.LIST_PAGE].list,
    totalListPagePlaylists: cacheLists[Cache.LIST_PAGE].total,
    setListPagePlaylists: (playlists: PlaylistModel[], total: number) => {
      saveCache(Cache.LIST_PAGE, playlists, total);
    },
  }
}

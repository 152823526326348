import { getApiRequestService } from "../../../services/index.services";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { usePlaylistCache } from "../hooks/usePlaylistCache";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  medicalSpecialties?: string;
  tags?: string;
}

export async function getPublicPlaylists(params: QueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/playlist/public`, {
      params: {
        limit: params.limit,
        offset: params.offset,
        language: params.language,
        medical_specialties: params.medicalSpecialties,
        tags: params.tags,
        status: "published",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export function usePublicPlaylistsWithCache() {
  const { publicPlaylists, setPublicPlaylists } = usePlaylistCache();

  // TODO: Missing catch.
  return async function (params: QueryParams) {
    const isAdding = params.offset === -1;
    if (isAdding) params.offset = publicPlaylists.length;

    const { docs, meta } = await getPublicPlaylists(params);
    setPublicPlaylists(isAdding ? [...publicPlaylists, ...docs] : docs, meta.total);

    return { docs, meta };
  }
}
